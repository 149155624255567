.App {
	font-family: sans-serif;
	text-align: center;
	list-style-position: inside;
}

a {
	color: inherit;
	text-decoration: none;
}
